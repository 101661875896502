function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    return Constructor;
}
var Subtitle = function () {
    function Subtitle(container, video, options, events) {
        _classCallCheck(this, Subtitle);
        this.container = container;
        this.video = video;
        this.options = options;
        this.events = events;
        this.init();
    }
    _createClass(Subtitle, [
        {
            key: 'init',
            value: function init() {
                var _this = this;
                this.container.style.fontSize = this.options.fontSize;
                this.container.style.bottom = this.options.bottom;
                this.container.style.color = this.options.color;
                if (this.video.textTracks && this.video.textTracks[0]) {
                    var track = this.video.textTracks[0];
                    track.oncuechange = function () {
                        var cue = track.activeCues[0];
                        _this.container.innerHTML = '';
                        if (cue) {
                            var template = document.createElement('div');
                            template.appendChild(cue.getCueAsHTML());
                            var trackHtml = template.innerHTML.split(/\r?\n/).map(function (item) {
                                return '<p>'.concat(item, '</p>');
                            }).join('');
                            _this.container.innerHTML = trackHtml;
                        }
                        _this.events.trigger('subtitle_change');
                    };
                }
            }
        },
        {
            key: 'show',
            value: function show() {
                this.container.classList.remove('dplayer-subtitle-hide');
                this.events.trigger('subtitle_show');
            }
        },
        {
            key: 'hide',
            value: function hide() {
                this.container.classList.add('dplayer-subtitle-hide');
                this.events.trigger('subtitle_hide');
            }
        },
        {
            key: 'toggle',
            value: function toggle() {
                if (this.container.classList.contains('dplayer-subtitle-hide')) {
                    this.show();
                } else {
                    this.hide();
                }
            }
        }
    ]);
    return Subtitle;
}();
export default Subtitle;