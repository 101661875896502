function _typeof(obj) {
    '@babel/helpers - typeof';
    if (typeof Symbol === 'function' && typeof Symbol.iterator === 'symbol') {
        _typeof = function _typeof(obj) {
            return typeof obj;
        };
    } else {
        _typeof = function _typeof(obj) {
            return obj && typeof Symbol === 'function' && obj.constructor === Symbol && obj !== Symbol.prototype ? 'symbol' : typeof obj;
        };
    }
    return _typeof(obj);
}
var g;
g = function () {
    return this;
}();
try {
    g = g || new Function('return this')();
} catch (e) {
    if ((typeof window === 'undefined' ? 'undefined' : _typeof(window)) === 'object')
        g = window;
}
module.exports = g;