function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    return Constructor;
}
import utils from './utils';
var Setting = function () {
    function Setting(player) {
        var _this = this;
        _classCallCheck(this, Setting);
        this.player = player;
        this.player.template.mask.addEventListener('click', function () {
            _this.hide();
        });
        this.player.template.settingButton.addEventListener('click', function () {
            _this.show();
        });
        this.loop = this.player.options.loop;
        this.player.template.loopToggle.checked = this.loop;
        this.player.template.loop.addEventListener('click', function () {
            _this.player.template.loopToggle.checked = !_this.player.template.loopToggle.checked;
            if (_this.player.template.loopToggle.checked) {
                _this.loop = true;
            } else {
                _this.loop = false;
            }
            _this.hide();
        });
        this.showDanmaku = this.player.user.get('danmaku');
        if (!this.showDanmaku) {
            this.player.danmaku && this.player.danmaku.hide();
        }
        this.player.template.showDanmakuToggle.checked = this.showDanmaku;
        this.player.template.showDanmaku.addEventListener('click', function () {
            _this.player.template.showDanmakuToggle.checked = !_this.player.template.showDanmakuToggle.checked;
            if (_this.player.template.showDanmakuToggle.checked) {
                _this.showDanmaku = true;
                _this.player.danmaku.show();
            } else {
                _this.showDanmaku = false;
                _this.player.danmaku.hide();
            }
            _this.player.user.set('danmaku', _this.showDanmaku ? 1 : 0);
            _this.hide();
        });
        this.unlimitDanmaku = this.player.user.get('unlimited');
        this.player.template.unlimitDanmakuToggle.checked = this.unlimitDanmaku;
        this.player.template.unlimitDanmaku.addEventListener('click', function () {
            _this.player.template.unlimitDanmakuToggle.checked = !_this.player.template.unlimitDanmakuToggle.checked;
            if (_this.player.template.unlimitDanmakuToggle.checked) {
                _this.unlimitDanmaku = true;
                _this.player.danmaku.unlimit(true);
            } else {
                _this.unlimitDanmaku = false;
                _this.player.danmaku.unlimit(false);
            }
            _this.player.user.set('unlimited', _this.unlimitDanmaku ? 1 : 0);
            _this.hide();
        });
        this.player.template.speed.addEventListener('click', function () {
            _this.player.template.settingBox.classList.add('dplayer-setting-box-narrow');
            _this.player.template.settingBox.classList.add('dplayer-setting-box-speed');
        });
        var _loop = function _loop(i) {
            _this.player.template.speedItem[i].addEventListener('click', function () {
                _this.player.speed(_this.player.template.speedItem[i].dataset.speed);
                _this.hide();
            });
        };
        for (var i = 0; i < this.player.template.speedItem.length; i++) {
            _loop(i);
        }
        if (this.player.danmaku) {
            var dWidth = 130;
            this.player.on('danmaku_opacity', function (percentage) {
                _this.player.bar.set('danmaku', percentage, 'width');
                _this.player.user.set('opacity', percentage);
            });
            this.player.danmaku.opacity(this.player.user.get('opacity'));
            var danmakuMove = function danmakuMove(event) {
                var e = event || window.event;
                var percentage = ((e.clientX || e.changedTouches[0].clientX) - utils.getBoundingClientRectViewLeft(_this.player.template.danmakuOpacityBarWrap)) / dWidth;
                percentage = Math.max(percentage, 0);
                percentage = Math.min(percentage, 1);
                _this.player.danmaku.opacity(percentage);
            };
            var danmakuUp = function danmakuUp() {
                document.removeEventListener(utils.nameMap.dragEnd, danmakuUp);
                document.removeEventListener(utils.nameMap.dragMove, danmakuMove);
                _this.player.template.danmakuOpacityBox.classList.remove('dplayer-setting-danmaku-active');
            };
            this.player.template.danmakuOpacityBarWrapWrap.addEventListener('click', function (event) {
                var e = event || window.event;
                var percentage = ((e.clientX || e.changedTouches[0].clientX) - utils.getBoundingClientRectViewLeft(_this.player.template.danmakuOpacityBarWrap)) / dWidth;
                percentage = Math.max(percentage, 0);
                percentage = Math.min(percentage, 1);
                _this.player.danmaku.opacity(percentage);
            });
            this.player.template.danmakuOpacityBarWrapWrap.addEventListener(utils.nameMap.dragStart, function () {
                document.addEventListener(utils.nameMap.dragMove, danmakuMove);
                document.addEventListener(utils.nameMap.dragEnd, danmakuUp);
                _this.player.template.danmakuOpacityBox.classList.add('dplayer-setting-danmaku-active');
            });
        }
    }
    _createClass(Setting, [
        {
            key: 'hide',
            value: function hide() {
                var _this2 = this;
                this.player.template.settingBox.classList.remove('dplayer-setting-box-open');
                this.player.template.mask.classList.remove('dplayer-mask-show');
                setTimeout(function () {
                    _this2.player.template.settingBox.classList.remove('dplayer-setting-box-narrow');
                    _this2.player.template.settingBox.classList.remove('dplayer-setting-box-speed');
                }, 300);
                this.player.controller.disableAutoHide = false;
            }
        },
        {
            key: 'show',
            value: function show() {
                this.player.template.settingBox.classList.add('dplayer-setting-box-open');
                this.player.template.mask.classList.add('dplayer-mask-show');
                this.player.controller.disableAutoHide = true;
            }
        }
    ]);
    return Setting;
}();
export default Setting;