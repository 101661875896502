function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly)
            symbols = symbols.filter(function (sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i] != null ? arguments[i] : {};
        if (i % 2) {
            ownKeys(Object(source), true).forEach(function (key) {
                _defineProperty(target, key, source[key]);
            });
        } else if (Object.getOwnPropertyDescriptors) {
            Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
        } else {
            ownKeys(Object(source)).forEach(function (key) {
                Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
            });
        }
    }
    return target;
}
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    return Constructor;
}
import Promise from 'promise-polyfill';
import utils from './utils';
import handleOption from './options';
import i18n from './i18n';
import Template from './template';
import Icons from './icons';
import Danmaku from './danmaku';
import Events from './events';
import FullScreen from './fullscreen';
import User from './user';
import Subtitle from './subtitle';
import Bar from './bar';
import Timer from './timer';
import Bezel from './bezel';
import Controller from './controller';
import Setting from './setting';
import PausedImg from './paused-img';
import Comment from './comment';
import HotKey from './hotkey';
import ContextMenu from './contextmenu';
import InfoPanel from './info-panel';
import tplVideo from '../template/video.art';
var index = 0;
var instances = [];
var DPlayer = function () {
    function DPlayer(options) {
        var _this = this;
        _classCallCheck(this, DPlayer);
        this.options = handleOption(_objectSpread({ preload: options.video.type === 'webtorrent' ? 'none' : 'metadata' }, options));
        if (this.options.video.quality) {
            this.qualityIndex = this.options.video.defaultQuality;
            this.quality = this.options.video.quality[this.options.video.defaultQuality];
        }
        this.tran = new i18n(this.options.lang).tran;
        this.events = new Events();
        this.user = new User(this);
        this.container = this.options.container;
        this.container.classList.add('dplayer');
        if (!this.options.danmaku) {
            this.container.classList.add('dplayer-no-danmaku');
        }
        if (this.options.live) {
            this.container.classList.add('dplayer-live');
        } else {
            this.container.classList.remove('dplayer-live');
        }
        if (utils.isMobile) {
            this.container.classList.add('dplayer-mobile');
        }
        this.arrow = this.container.offsetWidth <= 500;
        if (this.arrow) {
            this.container.classList.add('dplayer-arrow');
        }
        this.template = new Template({
            container: this.container,
            options: this.options,
            index: index,
            tran: this.tran
        });
        this.video = this.template.video;
        this.bar = new Bar(this.template);
        this.bezel = new Bezel(this.template.bezel);
        this.imgsPaused = new PausedImg(this.template);
        this.fullScreen = new FullScreen(this);
        this.controller = new Controller(this);
        if (this.options.danmaku) {
            this.danmaku = new Danmaku({
                container: this.template.danmaku,
                opacity: this.user.get('opacity'),
                callback: function callback() {
                    setTimeout(function () {
                        _this.template.danmakuLoading.style.display = 'none';
                        if (_this.options.autoplay) {
                            _this.play();
                        }
                    }, 0);
                },
                error: function error(msg) {
                    _this.notice(msg);
                },
                apiBackend: this.options.apiBackend,
                borderColor: this.options.theme,
                height: this.arrow ? 24 : 30,
                time: function time() {
                    return _this.video.currentTime;
                },
                unlimited: this.user.get('unlimited'),
                api: {
                    id: this.options.danmaku.id,
                    address: this.options.danmaku.api,
                    token: this.options.danmaku.token,
                    maximum: this.options.danmaku.maximum,
                    addition: this.options.danmaku.addition,
                    user: this.options.danmaku.user
                },
                events: this.events,
                tran: function tran(msg) {
                    return _this.tran(msg);
                }
            });
            this.comment = new Comment(this);
        }
        this.setting = new Setting(this);
        this.plugins = {};
        document.addEventListener('click', function () {
            _this.focus = false;
        }, true);
        this.container.addEventListener('click', function () {
            _this.focus = true;
        }, true);
        this.paused = true;
        this.timer = new Timer(this);
        this.hotkey = new HotKey(this);
        this.contextmenu = new ContextMenu(this);
        this.initVideo(this.video, this.quality && this.quality.type || this.options.video.type);
        this.infoPanel = new InfoPanel(this);
        if (!this.danmaku && this.options.autoplay) {
            this.play();
        }
        index++;
        instances.push(this);
    }
    _createClass(DPlayer, [
        {
            key: 'seek',
            value: function seek(time) {
                time = Math.max(time, 0);
                if (this.video.duration) {
                    time = Math.min(time, this.video.duration);
                }
                if (this.video.currentTime < time) {
                    this.notice(''.concat(this.tran('FF'), ' ').concat((time - this.video.currentTime).toFixed(0), ' ').concat(this.tran('s')));
                } else if (this.video.currentTime > time) {
                    this.notice(''.concat(this.tran('REW'), ' ').concat((this.video.currentTime - time).toFixed(0), ' ').concat(this.tran('s')));
                }
                this.video.currentTime = time;
                if (this.danmaku) {
                    this.danmaku.seek();
                }
                this.bar.set('played', time / this.video.duration, 'width');
                this.template.ptime.innerHTML = utils.secondToTime(time);
            }
        },
        {
            key: 'play',
            value: function play(fromNative) {
                var _this2 = this;
                this.paused = false;
                if (this.video.paused && !utils.isMobile) {
                    this.bezel['switch'](Icons.pause, 'paused');
                }
                this.template.playButton.innerHTML = Icons.pause;
                this.template.mobilePlayButton.innerHTML = Icons.pause;
                this.imgsPaused['switch'](this.template.imgsPausedContent, 'paused');
                this.initAdClose();
                if (!fromNative) {
                    var playedPromise = Promise.resolve(this.video.play());
                    playedPromise['catch'](function () {
                        _this2.pause();
                    }).then(function () {
                    });
                }
                this.timer.enable('loading');
                this.container.classList.remove('dplayer-paused');
                this.container.classList.add('dplayer-playing');
                if (this.danmaku) {
                    this.danmaku.play();
                }
                if (this.options.mutex) {
                    for (var i = 0; i < instances.length; i++) {
                        if (this !== instances[i]) {
                            instances[i].pause();
                        }
                    }
                }
            }
        },
        {
            key: 'pause',
            value: function pause(fromNative) {
                this.paused = true;
                this.container.classList.remove('dplayer-loading');
                if (!this.video.paused && !utils.isMobile) {
                }
                this.template.playButton.innerHTML = Icons.play;
                this.template.mobilePlayButton.innerHTML = Icons.play;
                if (!fromNative) {
                    this.video.pause();
                }
                this.bezel['switch'](Icons.play, 'play');
                this.imgsPaused['switch'](this.template.imgsPausedContent, 'play');
                this.timer.disable('loading');
                this.container.classList.remove('dplayer-playing');
                this.container.classList.add('dplayer-paused');
                if (this.danmaku) {
                    this.danmaku.pause();
                }
            }
        },
        {
            key: 'switchVolumeIcon',
            value: function switchVolumeIcon() {
                if (this.volume() >= 0.95) {
                    this.template.volumeIcon.innerHTML = Icons.volumeUp;
                } else if (this.volume() > 0) {
                    this.template.volumeIcon.innerHTML = Icons.volumeDown;
                } else {
                    this.template.volumeIcon.innerHTML = Icons.volumeOff;
                }
            }
        },
        {
            key: 'volume',
            value: function volume(percentage, nostorage, nonotice) {
                percentage = parseFloat(percentage);
                if (!isNaN(percentage)) {
                    percentage = Math.max(percentage, 0);
                    percentage = Math.min(percentage, 1);
                    this.bar.set('volume', percentage, 'width');
                    var formatPercentage = ''.concat((percentage * 100).toFixed(0), '%');
                    this.template.volumeBarWrapWrap.dataset.balloon = formatPercentage;
                    if (!nostorage) {
                        this.user.set('volume', percentage);
                    }
                    if (!nonotice) {
                        this.notice(''.concat(this.tran('Volume'), ' ').concat((percentage * 100).toFixed(0), '%'));
                    }
                    this.video.volume = percentage;
                    if (this.video.muted) {
                        this.video.muted = false;
                    }
                    this.switchVolumeIcon();
                }
                return this.video.volume;
            }
        },
        {
            key: 'toggle',
            value: function toggle() {
                if (this.video.paused) {
                    this.play();
                } else {
                    this.pause();
                }
            }
        },
        {
            key: 'on',
            value: function on(name, callback) {
                this.events.on(name, callback);
            }
        },
        {
            key: 'switchVideo',
            value: function switchVideo(video, danmakuAPI) {
                this.pause();
                this.video.poster = video.pic ? video.pic : '';
                this.video.src = video.url;
                this.initMSE(this.video, video.type || 'auto');
                if (danmakuAPI) {
                    this.template.danmakuLoading.style.display = 'block';
                    this.bar.set('played', 0, 'width');
                    this.bar.set('loaded', 0, 'width');
                    this.template.ptime.innerHTML = '00:00';
                    this.template.danmaku.innerHTML = '';
                    if (this.danmaku) {
                        this.danmaku.reload({
                            id: danmakuAPI.id,
                            address: danmakuAPI.api,
                            token: danmakuAPI.token,
                            maximum: danmakuAPI.maximum,
                            addition: danmakuAPI.addition,
                            user: danmakuAPI.user
                        });
                    }
                }
            }
        },
        {
            key: 'initMSE',
            value: function initMSE(video, type) {
                var _this3 = this;
                this.type = type;
                if (this.options.video.customType && this.options.video.customType[type]) {
                    if (Object.prototype.toString.call(this.options.video.customType[type]) === '[object Function]') {
                        this.options.video.customType[type](this.video, this);
                    } else {
                        console.error('Illegal customType: '.concat(type));
                    }
                } else {
                    if (this.type === 'auto') {
                        if (/m3u8(#|\?|$)/i.exec(video.src)) {
                            this.type = 'hls';
                        } else if (/.flv(#|\?|$)/i.exec(video.src)) {
                            this.type = 'flv';
                        } else if (/.mpd(#|\?|$)/i.exec(video.src)) {
                            this.type = 'dash';
                        } else {
                            this.type = 'normal';
                        }
                    }
                    if (this.type === 'hls' && (video.canPlayType('application/x-mpegURL') || video.canPlayType('application/vnd.apple.mpegURL'))) {
                        this.type = 'normal';
                    }
                    switch (this.type) {
                    case 'hls':
                        if (window.Hls) {
                            if (window.Hls.isSupported()) {
                                var options = this.options.pluginOptions.hls;
                                var hls = new window.Hls(options);
                                this.plugins.hls = hls;
                                hls.loadSource(video.src);
                                hls.attachMedia(video);
                                this.events.on('destroy', function () {
                                    hls.destroy();
                                    delete _this3.plugins.hls;
                                });
                            } else {
                                this.notice('Error: Hls is not supported.');
                            }
                        } else {
                            this.notice('Error: Can\'t find Hls.');
                        }
                        break;
                    case 'flv':
                        if (window.flvjs) {
                            if (window.flvjs.isSupported()) {
                                var flvPlayer = window.flvjs.createPlayer(Object.assign(this.options.pluginOptions.flv.mediaDataSource || {}, {
                                    type: 'flv',
                                    url: video.src
                                }), this.options.pluginOptions.flv.config);
                                this.plugins.flvjs = flvPlayer;
                                flvPlayer.attachMediaElement(video);
                                flvPlayer.load();
                                this.events.on('destroy', function () {
                                    flvPlayer.unload();
                                    flvPlayer.detachMediaElement();
                                    flvPlayer.destroy();
                                    delete _this3.plugins.flvjs;
                                });
                            } else {
                                this.notice('Error: flvjs is not supported.');
                            }
                        } else {
                            this.notice('Error: Can\'t find flvjs.');
                        }
                        break;
                    case 'dash':
                        if (window.dashjs) {
                            var dashjsPlayer = window.dashjs.MediaPlayer().create().initialize(video, video.src, false);
                            var _options = this.options.pluginOptions.dash;
                            dashjsPlayer.updateSettings(_options);
                            this.plugins.dash = dashjsPlayer;
                            this.events.on('destroy', function () {
                                window.dashjs.MediaPlayer().reset();
                                delete _this3.plugins.dash;
                            });
                        } else {
                            this.notice('Error: Can\'t find dashjs.');
                        }
                        break;
                    case 'webtorrent':
                        if (window.WebTorrent) {
                            if (window.WebTorrent.WEBRTC_SUPPORT) {
                                this.container.classList.add('dplayer-loading');
                                var _options2 = this.options.pluginOptions.webtorrent;
                                var client = new window.WebTorrent(_options2);
                                this.plugins.webtorrent = client;
                                var torrentId = video.src;
                                video.src = '';
                                video.preload = 'metadata';
                                video.addEventListener('durationchange', function () {
                                    return _this3.container.classList.remove('dplayer-loading');
                                }, { once: true });
                                client.add(torrentId, function (torrent) {
                                    var file = torrent.files.find(function (file) {
                                        return file.name.endsWith('.mp4');
                                    });
                                    file.renderTo(_this3.video, {
                                        autoplay: _this3.options.autoplay,
                                        controls: false
                                    });
                                });
                                this.events.on('destroy', function () {
                                    client.remove(torrentId);
                                    client.destroy();
                                    delete _this3.plugins.webtorrent;
                                });
                            } else {
                                this.notice('Error: Webtorrent is not supported.');
                            }
                        } else {
                            this.notice('Error: Can\'t find Webtorrent.');
                        }
                        break;
                    }
                }
            }
        },
        {
            key: 'initVideo',
            value: function initVideo(video, type) {
                var _this4 = this;
                this.initMSE(video, type);
                this.on('durationchange', function () {
                    if (video.duration !== 1 && video.duration !== Infinity) {
                        _this4.template.dtime.innerHTML = utils.secondToTime(video.duration);
                    }
                });
                this.on('progress', function () {
                    var percentage = video.buffered.length ? video.buffered.end(video.buffered.length - 1) / video.duration : 0;
                    _this4.bar.set('loaded', percentage, 'width');
                });
                this.on('error', function () {
                    if (!_this4.video.error) {
                        return;
                    }
                    _this4.tran && _this4.notice && _this4.type !== 'webtorrent' && _this4.notice(_this4.tran('Video load failed'), -1);
                });
                this.on('ended', function () {
                    _this4.bar.set('played', 1, 'width');
                    if (!_this4.setting.loop) {
                        _this4.pause();
                    } else {
                        _this4.seek(0);
                        _this4.play();
                    }
                    if (_this4.danmaku) {
                        _this4.danmaku.danIndex = 0;
                    }
                });
                this.on('play', function () {
                    if (_this4.paused) {
                        _this4.play(true);
                    }
                });
                this.on('pause', function () {
                    if (!_this4.paused) {
                        _this4.pause(true);
                    }
                });
                this.on('timeupdate', function () {
                    _this4.bar.set('played', _this4.video.currentTime / _this4.video.duration, 'width');
                    var currentTime = utils.secondToTime(_this4.video.currentTime);
                    if (_this4.template.ptime.innerHTML !== currentTime) {
                        _this4.template.ptime.innerHTML = currentTime;
                    }
                });
                var _loop = function _loop(i) {
                    video.addEventListener(_this4.events.videoEvents[i], function () {
                        _this4.events.trigger(_this4.events.videoEvents[i]);
                    });
                };
                for (var i = 0; i < this.events.videoEvents.length; i++) {
                    _loop(i);
                }
                this.volume(this.user.get('volume'), true, true);
                if (this.options.subtitle) {
                    this.subtitle = new Subtitle(this.template.subtitle, this.video, this.options.subtitle, this.events);
                    if (!this.user.get('subtitle')) {
                        this.subtitle.hide();
                    }
                }
            }
        },
        {
            key: 'switchQuality',
            value: function switchQuality(index) {
                var _this5 = this;
                index = typeof index === 'string' ? parseInt(index) : index;
                if (this.qualityIndex === index || this.switchingQuality) {
                    return;
                } else {
                    this.qualityIndex = index;
                }
                this.switchingQuality = true;
                this.quality = this.options.video.quality[index];
                this.template.qualityButton.innerHTML = this.quality.name;
                var paused = this.video.paused;
                this.video.pause();
                var videoHTML = tplVideo({
                    current: false,
                    pic: null,
                    screenshot: this.options.screenshot,
                    preload: 'auto',
                    url: this.quality.url,
                    subtitle: this.options.subtitle
                });
                var videoEle = new DOMParser().parseFromString(videoHTML, 'text/html').body.firstChild;
                this.template.videoWrap.insertBefore(videoEle, this.template.videoWrap.getElementsByTagName('div')[0]);
                this.prevVideo = this.video;
                this.video = videoEle;
                this.initVideo(this.video, this.quality.type || this.options.video.type);
                this.seek(this.prevVideo.currentTime);
                this.notice(''.concat(this.tran('Switching to'), ' ').concat(this.quality.name, ' ').concat(this.tran('quality')), -1);
                this.events.trigger('quality_start', this.quality);
                this.on('canplay', function () {
                    if (_this5.prevVideo) {
                        if (_this5.video.currentTime !== _this5.prevVideo.currentTime) {
                            _this5.seek(_this5.prevVideo.currentTime);
                            return;
                        }
                        _this5.template.videoWrap.removeChild(_this5.prevVideo);
                        _this5.video.classList.add('dplayer-video-current');
                        if (!paused) {
                            _this5.video.play();
                        }
                        _this5.prevVideo = null;
                        _this5.notice(''.concat(_this5.tran('Switched to'), ' ').concat(_this5.quality.name, ' ').concat(_this5.tran('quality')));
                        _this5.switchingQuality = false;
                        _this5.events.trigger('quality_end');
                    }
                });
            }
        },
        {
            key: 'notice',
            value: function notice(text) {
                var _this6 = this;
                var time = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2000;
                var opacity = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0.8;
                this.template.notice.innerHTML = text;
                this.template.notice.style.opacity = opacity;
                if (this.noticeTime) {
                    clearTimeout(this.noticeTime);
                }
                this.events.trigger('notice_show', text);
                if (time > 0) {
                    this.noticeTime = setTimeout(function () {
                        _this6.template.notice.style.opacity = 0;
                        _this6.events.trigger('notice_hide');
                    }, time);
                }
            }
        },
        {
            key: 'resize',
            value: function resize() {
                if (this.danmaku) {
                    this.danmaku.resize();
                }
                if (this.controller.thumbnails) {
                    this.controller.thumbnails.resize(160, this.video.videoHeight / this.video.videoWidth * 160, this.template.barWrap.offsetWidth);
                }
                this.events.trigger('resize');
            }
        },
        {
            key: 'speed',
            value: function speed(rate) {
                this.video.playbackRate = rate;
            }
        },
        {
            key: 'destroy',
            value: function destroy() {
                instances.splice(instances.indexOf(this), 1);
                this.pause();
                this.controller.destroy();
                this.timer.destroy();
                this.video.src = '';
                this.container.innerHTML = '';
                this.events.trigger('destroy');
            }
        },
        {
            key: 'initAdClose',
            value: function initAdClose() {
                var _this7 = this;
                if (this.template) {
                    var $template = this.template;
                    $template.imgPausedClose && $template.imgPausedClose.addEventListener('click', function () {
                        $template.images && $template.images.classList.remove('show');
                        $template.imgsPausedContent && $template.imgsPausedContent.classList.remove('show');
                        _this7.play();
                    });
                }
            }
        }
    ], [{
            key: 'version',
            get: function get() {
                return DPLAYER_VERSION;
            }
        }]);
    return DPlayer;
}();
export default DPlayer;