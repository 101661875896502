import axios from 'axios';
export default {
    send: function send(options) {
        axios.post(options.url, options.data).then(function (response) {
            var data = response.data;
            if (!data || data.code !== 0) {
                options.error && options.error(data && data.msg);
                return;
            }
            options.success && options.success(data);
        })['catch'](function (e) {
            console.error(e);
            options.error && options.error();
        });
    },
    read: function read(options) {
        axios.get(options.url).then(function (response) {
            var data = response.data;
            if (!data || data.code !== 0) {
                options.error && options.error(data && data.msg);
                return;
            }
            options.success && options.success(data.data.map(function (item) {
                return {
                    time: item[0],
                    type: item[1],
                    color: item[2],
                    author: item[3],
                    text: item[4]
                };
            }));
        })['catch'](function (e) {
            console.error(e);
            options.error && options.error();
        });
    }
};