function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    return Constructor;
}
var PausedImg = function () {
    function PausedImg(container) {
        _classCallCheck(this, PausedImg);
        this.container = container.imgsPausedContent;
        this.images = container.images;
    }
    _createClass(PausedImg, [{
            key: 'switch',
            value: function _switch(icon) {
                var flag = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'play';
                if (flag === 'play') {
                    this.images && this.images.classList.add('show');
                    this.container && this.container.classList.add('show');
                } else {
                    this.images && this.images.classList.remove('show');
                    this.container && this.container.classList.remove('show');
                }
            }
        }]);
    return PausedImg;
}();
export default PausedImg;